import { inject, ref } from 'vue';
import { pageKey, routeKey, setMeta, Meta } from '@drapejs/core';
import { injectionKeys as litiumKeys } from '@motillo/drapejs-litium';
import useContext from '@/composables/useContext';

function setMetaLocal(title: string, description: string, image: string, robots: string) {
  if (typeof window == 'undefined' || !document || !document.head) {
    return;
  }
  const route = inject(routeKey, <any>{});
  const { channel } = useContext();
  const availableChannels = inject('$availableChannelsReactive', ref());

  const locale = channel.value.locale || '';
  const head = document.head;
  const titleTag = document.querySelector('title') || document.createElement('title');
  titleTag.textContent = title || '';
  head.appendChild(titleTag);

  const langTag = document.querySelector('html') || document.documentElement;
  langTag.setAttribute('lang', locale);

  const metaTags = {
    'og:title': title,
    'og:description': description,
    description: description,
    'og:image': image,
    'og:type': 'website',
    robots: robots
  };

  Object.entries(metaTags).forEach(([key, value]) => {
    let metaTag = document.querySelector(`meta[name="${key}"]`) || document.createElement('meta');
    metaTag.setAttribute('name', key);
    metaTag.setAttribute('content', value ? String(value) : '');
    head.appendChild(metaTag);
  });

  const oldCanonicalLinks = document.querySelectorAll('link[rel="canonical"]');
  oldCanonicalLinks.forEach((link) => link.remove());

  availableChannels.value?.data?.forEach((link: any) => {
    let url = link.url + '/' + route.pathname.split(channel.value.rootPath)[1];
    let canonicalLink = document.querySelector(`link[rel="canonical"][href="${url}"][hreflang="${link.locale}"]`) || document.createElement('link');
    canonicalLink.setAttribute('rel', 'canonical');
    canonicalLink.setAttribute('href', url);
    canonicalLink.setAttribute('hreflang', link.locale || 'en');
    head.appendChild(canonicalLink);
  });
}

export function setProductMeta(selectedVariant: any, product: any) {
  const maxLengthForMetaDescription = 155;
  const toLitiumMediaUrl = inject(litiumKeys.toLitiumMediaUrlKey, () => '');
  const title = selectedVariant?.fields?._name || product?.fields?._name;
  let description = selectedVariant?.fields?._description || product?.fields?._description;
  if (description?.length > maxLengthForMetaDescription) {
    description = description.substring(0, maxLengthForMetaDescription - 3).concat('...');
  }
  const imageId = selectedVariant?.images?.[0]?.id;
  const image = imageId ? toLitiumMediaUrl(imageId, { maxWidth: 1200 }) : '';
  const robots = '';
  setMetaLocal(title, description, image, robots);
}

export function setCategoryMeta() {
  const page = inject(pageKey, ref());
  const toLitiumMediaUrl = inject(litiumKeys.toLitiumMediaUrlKey, () => '');
  const seo = page.value.dataJson?.dataJson?.category.seo;
  const title = seo?.title;
  const description = seo?.description;
  const image = seo?.imageId ? toLitiumMediaUrl(seo?.imageId, { maxWidth: 1200, maxHeight: 630 }) : '';
  const robots = seo.robots;
  setMetaLocal(title, description, image, robots);
}

export function setDefaultMeta() {
  const page = inject(pageKey, ref());
  const toLitiumMediaUrl = inject(litiumKeys.toLitiumMediaUrlKey, () => '');
  const seo = page.value.dataJson?.dataJson?.Seo;
  const title = seo?.title || page.value.title || page.value.name || page.value.fields?.Title || page.value.fields?._name;
  const description = seo?.description || page.value.fields?._description || null;
  const image = seo?.imageId ? toLitiumMediaUrl(seo?.imageId, { maxWidth: 1200, maxHeight: 630 }) : '';
  const robots = seo?.robots || '';
  setMetaLocal(title, description, image, robots);
}
